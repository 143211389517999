@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");

:root {
  --primary-color: #2a2c3e;
  --secondary-color: #be66ee;
  --tertiary-color: #ffffff;
  --grey: #f2f2f2;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "IBM Plex Sans Thai", sans-serif;
}

body {
  color: var(--primary-color);
}

.App {
  width: 65vw;
  margin: 3rem auto 0 auto;
  gap: 2rem;
}

.main-container {
  width: 100%;
}

.heading {
  color: var(--primary-color);
  font-weight: 500;
  font-size: 32px;
  text-align: center;
  line-height: 40px;
}

.clCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.inactive {
  background: var(--grey);
  border-radius: 24px;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
  cursor: pointer;
  transition: 0.3s ease;
  margin-bottom: 1rem;
}
.radio-container input[type="radio"] {
  display: none;
}

.inactive:hover {
  background: var(--secondary-color);
  color: white;
}

.active {
  background: var(--secondary-color);
  color: white;
}

.radio-container {
  margin-top: 4rem;
}

.inactive-small {
  width: 100%;
}

.radio-gird {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 4%;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .heading {
    font-size: 28px;
  }
}

@media screen and (max-width: 768px) {
  .heading {
    font-size: 24px;
  }

  .App {
    width: 80vw;
  }

  .radio-gird {
    display: block;
  }

  p {
    font-size: 14px;
  }
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.MuiCircularProgress-colorPrimary {
  color: var(--secondary-color) !important;
}
